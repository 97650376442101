<template>
  <b-card-actions
    ref="OrdersList"
    title="Pedidos"
    class="top-level"
    action-refresh
    @refresh="updateRows({})"
  >
    <b-row align-v="start">

      <b-col md="3">
        <b-form-group>
          <label>Pesquisar</label>
          <b-form-input
            v-model="$store.state.orders.filters.search"
            :disabled="state === 'loading'"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
            autocomplete="off"
            @change="(value)=>pesquisa()"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Status"
          label-for="filter_status"
        >
          <v-select
            id="filter_status"
            v-model="$store.state.orders.filters.status"
            label="text"
            class="bg-white"
            multiple
            :options="$store.state.orders.statusesList"
            :deselect-from-dropdown="true"
            :close-on-selec="false"
            :reduce="c => c.value"
            :disabled="state === 'loading'"
            :clearable="true"
            @input="(value)=>pesquisa()"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group>
          <label
            for="datepicker-end"
          >Data de início</label>
          <b-form-datepicker
            id="datepicker-end"
            v-model="$store.state.orders.filters.startDate"
            placeholder="Início"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            locale="pt-br"
            @input="(value)=>pesquisa()"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group>
          <label
            for="datepicker-end"
          >Data de fim</label>
          <b-form-datepicker
            id="datepicker-end"
            v-model="$store.state.orders.filters.endDate"
            placeholder="Fim"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            reset-button
            locale="pt-br"
            @input="(value)=>pesquisa()"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="2"
        class="mt-0 mt-md-2"
      >
        <b-form-group>
          <b-button
            class="float-right"
            variant="primary"
            :disabled="state === 'loading'"
            @click="() => {
              $store.commit('orders/filtersAll')
              pesquisa()
            }"
          >
            Todos
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row
            align-v="center"
            class="pb-1"
          >
            <b-col
              class="mt-1"
              cols="12"
              md="3"
            >
              <b>{{ `${selected.length}` }}</b> pedidos selecionados
            </b-col>
            <b-col
              class="mt-1"
              cols="6"
              md="2"
            >
              <b-button
                variant="primary"
                :disabled="state === 'loading'"
                @click="() => updateStatus('CANCELED', selected)"
              >
                Cancelado
              </b-button>
            </b-col>
            <b-col
              class="mt-1"
              cols="6"
              md="2"
            >
              <b-button
                variant="primary"
                :disabled="state === 'loading'"
                @click="() => updateStatus('PAID', selected)"
              >
                Pago
              </b-button>
            </b-col>
            <b-col
              class="mt-1"
              cols="6"
              md="2"
            >
              <b-button
                variant="primary"
                :disabled="state === 'loading'"
                @click="() => updateStatus('SENT', selected)"
              >
                Enviado
              </b-button>
            </b-col>
            <b-col
              class="mt-1"
              cols="6"
              md="2"
            >
              <b-button
                variant="primary"
                :disabled="state === 'loading'"
                @click="() => updateStatus('DELIVERED', selected)"
              >
                Entregue
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-1 mb-md-0">
      <b-col
        cols="12"
        md="3"
      >
        <b>{{ $store.state.orders.ordersList.length }}</b> de <b>{{ $store.state.orders.totalCount }}</b> Pedidos
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Selecionar tudo"
          label-cols="9"
        >
          <b-form-checkbox
            v-model="allSelected"
            class="mr-0 mt-50"
            name="all-selected"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          class="float-right my-1"
          variant="success"
          :to="{ name : 'order-create'}"
          :disabled="state === 'loading'"
        >
          Novo pedido
        </b-button>
      </b-col>
      <b-col
        md="3"
        class="mt-0 text-center"
      >
        <b-button
          variant="warning"
          :disabled="state === 'loading'"
          @click.stop="requestReport()"
        >Mail Relatório</b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          class="ml-1 my-1"
          @click="downloadOrders()"
        >
          Excel Pedidos
        </b-button>

      </b-col>
    </b-row>
    <div>
      <b-card
        v-for="order in rows"
        :key="order.id"
        :class="`mb-1 list-row ${selected.includes(order.id) ? 'row-selected' : ''} ${order.status}`"
        @click="toggleCard(order.id)"
      >
        <b-row align-v="center">
          <b-col
            lg="2"
            cols="12"
          >
            <b-row align-v="center">
              <b-col
                cols="1"
                class="px-0"
                @click.stop="() => {}"
              >
                <input
                  v-model="selected"
                  type="checkbox"
                  name=""
                  :value="order.id"
                >
              </b-col>
              <b-col
                cols="11"
                class="px-0"
              >
                <span
                  class="text-nowrap flex-parent"
                >
                  {{ order.identification }}.&nbsp;

                  <span class="flex-child long-and-truncated">{{ order.client_full_name }}</span>
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            lg="2"
            cols="6"
            class="text-center"
          >
            <span
              class="text-nowrap flex-parent py-0"
            >
              <span class="long-and-truncated"> {{ dateFormater.format(new Date(order.created)) }}</span>
            </span>
            <span>
              <b-badge
                v-for="lead in order.order_leads"
                :key="lead"
                pill
                variant="light-primary"
              >
                {{ lead }}
              </b-badge>
            </span>
          </b-col>
          <b-col
            lg="2"
            cols="6"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Status</span><br>
            {{ order.status_display }}
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Logística</span><br>
            {{ order.jadlog ? order.jadlog : '' }}
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
          >
            <whatsapp-menu
              :templates="$store.state.whatsapp.messageTemplates"
              :phone-number="`+55${order.phone.replace(/\D/g,'')}`"
              @click.stop="() => {}"
            />
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
            @click.stop="() => {}"
          >
            <b-form-group
              label="NFe"
              label-for="nfe_number"
            >
              <b-form-input
                id="nfe_number"
                v-model="order.nfe_number"
                :disabled="state === 'loading'"
                @change="(value) => updateNFe(order.id, value)"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Valor</span><br>
            {{ Number.parseFloat(order.final_value).toFixed(2) }}
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Pagamento</span><br>

            {{ order.payment_method }}
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">UF</span><br>
            {{ order.uf }}
          </b-col>
        </b-row>
        <b-row
          v-if="order.id === openedCard"
          align-v="center"
          class="py-1"
        >
          <b-col
            lg="2"
            cols="12"
          >
            <b-button
              :to="{ name: 'order-edit', params: { id: order.id } }"
              variant="success"
              :disabled="state === 'loading'"
            ><feather-icon icon="EditIcon" /> Editar</b-button>
          </b-col>
          <b-col
            lg="1"
            cols="6"
            class="mt-0 text-center"
            @click.stop="() => {}"
          >
            <b-form-group
              label="Número pedido de frete"
              label-for="tracking_number"
            >
              <b-form-input
                id="tracking_number"
                v-model="order.frete_tracking_number"
                :disabled="state === 'loading'"
                @change="(value) => updateTrackingNumber(order.id, value)"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!order.shippingchoice"
            cols="4"
            lg="3"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Jadlog</span><br>
            <b-button
              variant="primary"
              :disabled="state === 'loading'"
              @click.stop="updateJadlog(order.id)"
            ><feather-icon icon="RefreshCwIcon" />
              {{ order.jadlog ? order.jadlog : '' }}</b-button>

          </b-col>
          <b-col
            v-else
            cols="4"
            lg="3"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">{{ order.shippingchoice.shipping_option_company_name }}</span><br>
            <span>{{ order.shippingchoice.shipping_option_name }}</span>

          </b-col>
          <b-col
            cols="4"
            lg="2"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Link</span><br>

            <b-button
              variant="primary"
              :disabled="state === 'loading'"
              :href="order.link"
              target="blank"
              class="text-light"
            >
              <feather-icon icon="ExternalLinkIcon" /></b-button>

          </b-col>
          <b-col
            cols="4"
            lg="2"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">ContaAzul</span><br>
            <b-button
              variant="primary"
              :disabled="state === 'loading'"
              @click.stop="updateContaAzul(order.id)"
            ><feather-icon icon="RefreshCwIcon" />
              {{ order.sync_conta_azul !== '-' ? dateFormater.format(new Date(order.sync_conta_azul)) : '' }}</b-button>

          </b-col>
          <b-col
            cols="4"
            lg="2"
            class="mt-0 text-center"
          >
            <span style="font-size: 10px;">Delivery</span><br>
            {{ Number.parseFloat(order.delivery).toFixed(2) }}
          </b-col>

        </b-row>
        <b-row
          v-if="order.id === openedCard"
          align-v="center"
          class="pb-1"
        >
          <b-col
            v-for="product in order.product_choices"
            :key="product.id"
            cols="12"
          >
            <b-row>
              <b-col>
                <span
                  class="text-nowrap flex-parent"
                >
                  <b-avatar
                    :src="product.thumbnail_url"
                    class="mx-1"
                  />
                  <span class="flex-child long-and-truncated">{{ product.name }}<span v-if="product.additional_info"> - <b>{{ product.additional_info }}</b></span><br>
                    {{ product.quantity }} X {{ product.price }} - R$ {{ product.quantity * product.price }}</span>
                </span>
              </b-col>

            </b-row>

          </b-col>

        </b-row>
      </b-card>
    </div>
    <div class="d-flex justify-content-between flex-wrap mb-1">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Tamanho da página
        </span>
        <b-form-select
          v-model="$store.state.orders.filters.pageSize"
          :disabled="state === 'loading'"
          :options="['1','5','10', '50', '100', '250']"
          class="mx-1"
          @input="(value)=>updateRows({ pageSize: value, page: 1 })"
        />
      </div>
      <div>
        <b-pagination
          v-model="$store.state.orders.filters.page"
          :disabled="state === 'loading'"
          :total-rows="$store.state.orders.totalCount"
          :per-page="$store.state.orders.filters.pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value)=>updateRows({ page: value })"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WhatsappMenu from '@/components/WhatsappMenu.vue'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BBadge,
    WhatsappMenu,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    filter: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      allSelectState: false,
      openedCard: null,
    }
  },
  computed: {
    rows() {
      return this.$store.state.orders.ordersList
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.orders.ordersList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.orders.ordersList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    })
    if (this.filter) {
      this.$store.state.orders.filters.status = this.$store.state.orders.statusFilters[this.filter]
    }
    this.searchFilter = this.$store.state.orders.filters.search ? this.$store.state.orders.filters.search : ''
    this.pesquisa()
    if (this.$store.state.whatsapp.messageTemplates.length === 0) {
      this.$store.dispatch('whatsapp/fetchMessageTemplates')
    }
  },
  methods: {
    downloadOrders() {
      this.$store.dispatch('orders/downloadOrders').then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `orders_${Date.now()}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos baixar o arquivo',
            icon: 'UserIcon',
            variant: 'danger',
          },
        })
      })
    },
    toggleCard(id) {
      if (id === this.openedCard) {
        this.openedCard = null
      } else {
        this.openedCard = id
        this.state = 'loading'
        this.$store.dispatch('orders/getOrderById', id).then(response => {
          this.$store.commit('orders/updateOrderData', response.data)
        }).finally(() => {
          this.state = 'ready'
        })
      }
    },
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)

      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }

      if (index < 0) {
        this.state = 'loading'
        this.$store.dispatch('orders/getOrderById', id).then(response => {
          this.$store.commit('orders/updateOrderData', response.data)
        }).finally(() => {
          this.state = 'ready'
        })
      }
    },
    requestReport() {
      this.$store.dispatch('orders/requestReport').then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'O relatório será enviado por email.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'O pedido falhou.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      })
    },
    pesquisa() {
      this.state = 'loading'
      try {
        this.$refs.OrdersList.showLoading = true
      } catch (error) {

      }
      this.$store.commit('orders/firstPage')
      this.$store.dispatch('orders/getOrders', {}).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.orders.ordersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.OrdersList.showLoading = false
        } catch (error) {

        }
      })
    },
    updateStatus(status, ordersIds) {
      this.state = 'loading'
      this.$store.dispatch('orders/orderStatusUpdate', { status, ordersIds }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Status dos pedidos atualizados.',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Status dos pedidos não atualizados.',
            icon: 'SaveIcon',
            variant: 'warning',
          },
        })
      }).finally(() => {
        this.updateRows()
      })
    },
    updateRows(filters) {
      this.state = 'loading'
      try {
        this.$refs.OrdersList.showLoading = true
      } catch (error) {

      }
      this.$store.dispatch('orders/getOrders', filters).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.orders.ordersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.OrdersList.showLoading = false
        } catch (error) {

        }
      })
    },
    updateNFe(orderID, nfe) {
      this.state = 'loading'
      this.$store.dispatch('orders/updateNFe', {
        orderID,
        nfe,
      }).finally(() => {
        this.updateRows()
      })
    },
    updateTrackingNumber(orderID, trackingNumber) {
      this.state = 'loading'
      this.$store.dispatch('orders/updateTrackingNumber', {
        orderID,
        trackingNumber,
      }).finally(() => {
        this.updateRows()
      })
    },
    updateJadlog(orderID) {
      this.state = 'loading'
      this.$store.dispatch('orders/updateJadlog', orderID).then(() => {

      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A atualizaçõ da jadlog falhou. Tente novamente ou entre em contato com o suporte.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }).finally(() => {
        this.updateRows()
      })
    },
    updateContaAzul(orderID) {
      this.state = 'loading'
      this.$store.dispatch('orders/updateContaAzul', orderID).then(() => {

      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizaçõ do conta azul não realizada. Tente novamente ou entre em contato com o suporte.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }).finally(() => {
        this.updateRows()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
  color: inherit;
}

.CREATED {
}
.PAID, .RESERVED {
  background-color: rgb(255, 238, 182);
}
.SENT {
  background-color: rgb(190, 190, 190);
}
.DELIVERED {
  background-color: #a8f6d6;
}
.CANCELED {
  background-color: #ddd;
  color: #555;
}

// .row-selected {
//   background: #004259;
//   color: white !important;
// }

// .row-selected:hover {
//   background: #004159cc;
//   color: white;
// }

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
  // margin: 1em 0;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#filter_status{
  border-radius: 6px;
}
</style>
